import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'dar-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
  standalone: false
})
export class StepperComponent implements OnInit {

  @Input() public totalSteps = 6;

  @Input() public set currentStep(value: number) {
    setTimeout(() => {
      this._currentStep = value;
    }, 10);
  }
  public _currentStep = 0;

  public startAnimation = false;

  public ngOnInit(): void {
    setTimeout(() => {
      this.startAnimation = true;
    }, 10);
  }
}
