import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { finalize, forkJoin, Observable, OperatorFunction, retry } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import {
  RsAppInitializerReplaySubject
} from '../../components/web-component-app-initializer/classes/RsAppInitializerReplaySubject';
import { EnvironmentConfig } from '../../models/apps-environment';
import { UserInfoCookieAppName } from '../rs-authentication/enums/UserInfoCookieAppName.enum';
import { RentaDomains } from '../../../../models';
import { merge } from 'lodash';

export class RsMultiHttpTranslateLoader implements TranslateLoader {

  public readonly getTranslation = this.getTranslationRequest;

  constructor(
        private readonly httpClient: HttpClient,
        private readonly environmentProps: EnvironmentConfig,
        private readonly currentAppName: keyof typeof UserInfoCookieAppName,
        private readonly translationsLoaded$?: RsAppInitializerReplaySubject
  ) {
    this.currentAppName = this.currentAppName === 'ORD_ADMIN' ? 'ORD' : this.currentAppName;
  }

  private getTranslationRequest(lang: string): Observable<object> {
    const
      environment = this.environmentProps.weblateEnvironment != 'prod' ? `.${this.environmentProps.weblateEnvironment}.` : '.',
      appComponentName = `${this.currentAppName.toLowerCase()}-ui`,
      domain = location.hostname.includes(RentaDomains.EUROPE) ? RentaDomains.EUROPE : RentaDomains.RENTA;

    return forkJoin([
      this.httpClient.get(`https://translation${environment}${domain}/shared/${lang}.json`).pipe(weblateErrorHandling()),
      this.httpClient.get(`https://translation${environment}${domain}/${appComponentName}/${lang}.json`).pipe(weblateErrorHandling())
    ])
      .pipe(
        tap({
          next: () => {
            this.translationsLoaded$?.next(true);
          },
          error: (error) => {
            this.translationsLoaded$?.error({
              type: 'TRANSLATION',
              message: error
            });
          }
        }),
        finalize(() => {
          this.translationsLoaded$?.complete();
        }),
        map(response => {
          const object = this.deepmergeAll(response);
          return this.removeEmpty(object);
        })
      );
  }

  private removeEmpty(obj: object): object {
    return Object.entries(obj)
      .filter(([_first, second]) => second != '' && second != null)
      .reduce(
        (acc, [first, sec]) => ({
          ...acc,
          [first]: sec === Object(sec) ? this.removeEmpty(sec as object) : sec
        }),
        {}
      );
  }

  private deepmergeAll(array: Array<object>): object {
    if (!Array.isArray(array)) {
      throw new Error('first argument should be an array');
    }

    return array.reduce(function (prev, next) {
      return merge(prev, next);
    }, {});
  }
}

function weblateErrorHandling<T>(): OperatorFunction<T, T> {
  return (source$: Observable<T>): Observable<T> => {
    return source$.pipe(
      retry(2),
      catchError((error) => {
        throw `Error while loading the following translation file: ${error.url}`;
      }),
    );
  };
}
