import { AfterViewInit, Directive, ElementRef, Input, Renderer2 } from '@angular/core';

import { DEFAULTS, EVENTS } from './scroll-to-helpers';
import { ScrollToConfigOption, ScrollToOffsetMap, ScrollToTarget } from './scroll-to-config.interface';
import { ScrollToAnimationEasing } from './scroll-to-easing.interface';
import { ScrollToEvent } from './scroll-to-event.interface';
import { ScrollToService } from './scroll-to.service';

@Directive({
  selector: '[ngxScrollTo]',
  standalone: false
})
export class ScrollToDirective implements AfterViewInit {

  @Input() public ngxScrollTo: ScrollToTarget = DEFAULTS.target;

  @Input() public ngxScrollToEvent: ScrollToEvent = DEFAULTS.action;

  @Input() public ngxScrollToDuration: number | undefined = DEFAULTS.duration;

  @Input() public ngxScrollToEasing: ScrollToAnimationEasing | undefined = DEFAULTS.easing;

  @Input() public ngxScrollToOffset: number | undefined = DEFAULTS.offset;

  @Input() public ngxScrollToOffsetMap: ScrollToOffsetMap | undefined = DEFAULTS.offsetMap;

  private options!: ScrollToConfigOption;

  constructor(
    private readonly elementRef: ElementRef,
    private readonly scrollToService: ScrollToService,
    private readonly renderer2: Renderer2
  ) {
  }

  public ngAfterViewInit(): void {

    // Test Event Support
    if (EVENTS.indexOf(this.ngxScrollToEvent) === -1) {
      throw new Error(`Unsupported Event '${this.ngxScrollToEvent}'`);
    }

    // Listen for the trigger...
    this.renderer2.listen(
      this.elementRef.nativeElement,
      this.ngxScrollToEvent,
      (_event: Event) => {

        this.options = {
          target: this.ngxScrollTo,
          duration: this.ngxScrollToDuration,
          easing: this.ngxScrollToEasing,
          offset: this.ngxScrollToOffset,
          offsetMap: this.ngxScrollToOffsetMap
        };

        this.scrollToService.scrollTo(this.options);
      }
    );
  }
}
