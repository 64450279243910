import { Component, ContentChild, HostBinding, Input } from '@angular/core';
import { CdkStepper } from '@angular/cdk/stepper';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatStepLabel } from '@angular/material/stepper';
import { NgTemplateOutlet } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

/** rs-custom-stepper
 *
 * If needed to extend it, check how it was done on
 * https://github.com/angular/components/blob/master/src/material/stepper/stepper.ts
 *
 * Usage:
 *
 ```html
 <rs-mat-stepper>
 <cdk-step></cdk-step>
 <cdk-step></cdk-step>
 </rs-mat-stepper>
 ```
 */
@Component({
  selector: 'rs-mat-stepper',
  templateUrl: './rs-mat-stepper.component.html',
  styleUrls: ['./rs-mat-stepper.component.scss'],
  animations: [
    trigger('stepTransition', [
      state('previous', style({ transform: 'translate3d(-100%, 0, 0)', visibility: 'hidden' })),
      state('current', style({ transform: 'none', visibility: 'visible' })),
      state('next', style({ transform: 'translate3d(100%, 0, 0)', visibility: 'hidden' })),
      transition('* => *', animate('500ms cubic-bezier(0.35, 0, 0.25, 1)'))
    ])
  ],
  imports: [
    NgTemplateOutlet,
    TranslateModule
  ],
  providers: [{ provide: CdkStepper, useExisting: RsMatStepperComponent }]
})
export class RsMatStepperComponent extends CdkStepper {

  @Input() public showSteps: boolean = true;

  @HostBinding('class') @Input('class') public class?: string;
  @HostBinding('id') @Input('id') public id?: string;

  /** Content for step label given by `<ng-template matStepLabel>`. */
  @ContentChild(MatStepLabel) public stepLabel?: MatStepLabel;
}
