import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewContainerRef
} from '@angular/core';
import { isMobiSkin } from '../../../scripts/applicationsSkinSetter';

/** Used to add a loader inside a container
 *
 * For example when reloading form field value, it can be used on the container of the field
 *
 * MANUAL HIDE:
 * ```html
 <rs-section-loader
 [show]="boolean" OPTIONAL - Default false
 [message]="string" OPTIONAL - Default ''
 >
 </rs-section-loader>
 ```
 * AUTO HIDE:
 * ```html
 <rs-section-loader
 [(show)]="var:boolean" - Default false - [(...)] Double bound
 [message]="string" OPTIONAL - Default ''
 [hideDelay]="1000"
 [showDelay]="600"
 >
 </rs-section-loader>
 ```
 * Example on html tag:
 * ```html
 <div
 [rs-section-loader-message]="'FORM_LOADERS_MSG.RELOADING_VALUES'"
 [rs-section-loader-show]="isFormReadOnly ? false : loaders.insuranceBrokerFsmaNumber"
 rs-section-loader
 >
 </div>
 ```
 */
@Component({
  selector: 'rs-section-loader',
  templateUrl: './section-loader.component.html',
  styleUrls: ['./section-loader.component.scss'],
  standalone: false
})
export class SectionLoaderComponent implements OnInit {
  @HostBinding('class') @Input('class') public class?: string;
  /** Double binding on rs-section-loader-show
   *
   * When defining a double-binded variable, you will need to define one @Input-decorator with the variable name:
   * and one @Output-decorator with the variable name and Change after it, because this one emits the changing-event of the variable:
   *
   * ex: @Input() edit: boolean; && @Output() editChange: EventEmitter<boolean> = new EventEmitter<boolean>();
   * then use [(edit)] and when editChange.emit, it will change the parent value
   */
  @Output() public showChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  /** The message to be displayed in the loader
   *
   * string - optional - default = ''
   */
  @Input() public message: string = '';
  /** (OPTIONAL) The default delay in ms before showing the loader
   *
   * Used to prevent flickering
   */
  @Input() public hideDelay?: number;
  /** (OPTIONAL) The default delay in ms before automaticly hiding the loader
   *
   * applyed only if provided else must be done manualy with [show]
   *
   * Default 0
   */
  @Input() public showDelay: number = 0;
  /** The alignment of the message
   *
   * 'left' | 'center' | 'right' - optional - default = 'left'
   */
  @Input() public hAlignMessage: 'left' | 'center' | 'right' = 'left';
  /** The vertical alignment of the message
   *
   * 'top' | 'center' | 'bottom' - optional - default = 'center'
   */
  @Input() public vAlignMessage: 'top' | 'center' | 'bottom' = 'center';
  public readonly isMobiSkin = isMobiSkin;
  private showSpinner!: ReturnType<typeof setTimeout>;

  constructor(
    private readonly hostElement: ElementRef,
    private readonly renderer: Renderer2,
    private readonly viewContainerRef: ViewContainerRef
  ) {
  }

  private _show!: boolean;

  public get show(): boolean {
    return this._show;
  }

  /** Shows the loader
   *
   * boolean - default = false (hidden)
   */
  @Input()
  public set show(display: boolean) {
    if (display) {
      this.showSpinner = setTimeout(() => this._show = true, this.showDelay);
    } else {
      clearTimeout(this.showSpinner);
      this._show = false
    }

    // Reset val if autoHide
    if (display && this.hideDelay) {
      setTimeout(() => {
        this.showChange.emit(false);
      }, this.hideDelay);
    }

  }

  public ngOnInit(): void {
    this.hostElement.nativeElement.parentElement.style.position = 'relative';
  }
}
