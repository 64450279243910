import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import * as Sentry from '@sentry/angular';

@Injectable()
export class HttpErrorsInterceptor implements HttpInterceptor {

  constructor() {
  }

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((err: Error | HttpErrorResponse) => {
        if (err instanceof HttpErrorResponse) {
          Sentry.getCurrentScope().setTag('app.error-type', 'http-error');
          Sentry.getCurrentScope().setExtra('error', err);
          Sentry.getCurrentScope().setLevel('error');
          Sentry.getCurrentScope().setContext('error details-section', {
            'error-code': err.status,
            'backend-detailed-error': JSON.stringify(err.error)
          });

          Sentry.captureMessage('http-error');
        }
        return throwError(() => err);
      })
    );
  }
}
