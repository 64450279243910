import { SubheaderMenuItems } from './models/subheader-menu-items';
import { Component, HostListener, Inject, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationExtras, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { EnvironmentConfig } from '../../modules/rs-core/models/apps-environment';
import { filter, takeUntil } from 'rxjs/operators';
import { ENVIRONMENT } from '../../modules/rs-core/rs-core.module';
import { MatBadgeModule } from '@angular/material/badge';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';

/** Usage Example:
 * ```html
 <rs-sub-header
 [menuItems]="SubheaderMenuItems[]"
 [backLinkOptions]="{backRouterLink: '..', onClick: someFunction.bind(this)}"
 [menuComponent]="{
 component: Component;
 alignLeft: boolean;
 }"
 >
 Subheader
 </rs-sub-header>
 ```
 */
@Component({
  selector: 'rs-sub-header',
  templateUrl: './rs-sub-header.component.html',
  styleUrls: ['./rs-sub-header.component.scss'],
  imports: [
    CommonModule,
    MatBadgeModule,
    TranslateModule,
    MatIconModule,
    MatIconButton
  ]
})
export class RsSubHeaderComponent implements OnInit, OnDestroy {

  /** @param {string} backRouterLink If provided, will display a back arrow button with the provided router link.
   *
   * @param {boolean} isRouterLinkRelative if set to true will set relativeTo option to the activated route
   *
   * @param {Function} onClick If provided, will call the provided function before navigating to the backRouterLink.
   *
   * @param {boolean} preserveQueryParams If true, will add the current query parameters to the back arrow router link.
   **/
  @Input() public backLinkOptions?: {
    backRouterLink: unknown[];
    isRouterLinkRelative?: boolean;
    onClick?: Function,
    preserveQueryParams?: boolean
  };

  /** Menu component for the subheader, expects {
   * component: ng-template,
   * alignLeft: boolean default false
   * }
   **/
  @Input() public menuComponent?: {
    component: Component | TemplateRef<unknown> | null;
    alignLeft?: boolean;
  } = {
      component: null,
      alignLeft: false
    };
  public menuHamburgerOpen: boolean = false;
  public routerLink: string | unknown[] | null = null;
  private _submenuItems?: SubheaderMenuItems[];
  private destroy$: Subject<boolean> = new Subject<boolean>();

  public constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    @Inject(ENVIRONMENT) private readonly environment: EnvironmentConfig,
  ) {
  }

  /** Menu items for the subheader, expects [SubheaderMenuItems[]]{@link SubheaderMenuItems} */
  @Input()
  public set menuItems(menuItems: SubheaderMenuItems[]) {
    this._submenuItems = menuItems;
    this.selectMenuItemBasedOnUrl();
  }

  /** @ignore */
  public get _leftMenuItems(): SubheaderMenuItems[] {
    return this._submenuItems ? this._submenuItems.filter(menuItem => menuItem.alignLeft) : [];
  }

  /** @ignore */
  public get _rightMenuItems(): SubheaderMenuItems[] {
    return this._submenuItems ? this._submenuItems.filter(menuItem => !menuItem.alignLeft) : [];
  }

  public ngOnInit(): void {
    this.filterMenuItemsOnDisplayEnvironments();
    this.selectMenuItemBasedOnUrlOnRouterNavigationEnd();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  /** Nav items onClick event
   *
   * @param menuItem SubheaderMenuItems
   */
  public onClick(
    menuItem: SubheaderMenuItems
  ): void {

    if (menuItem.path) {
      this
        .router
        .navigate(
          [menuItem.path],
          menuItem.navigationExtras
        );
    } else if (menuItem.onClick) {
      menuItem.onClick();
      this.selectItem(menuItem);
    }

    if (this.menuHamburgerOpen) {
      this.toggleHamburgerMenu();
    }
  }

  /** Toggle hamburger menu and body no-scroll.
   * body.no-scroll is needed to not have to scroll
   */
  public toggleHamburgerMenu(): void {
    this.menuHamburgerOpen = !this.menuHamburgerOpen;

    document
      .getElementsByTagName('body')[0]
      .classList
      .toggle('no-scroll');
  }

  /** Clear hamburger menu if window width greater than md */
  @HostListener('window:resize', ['$event'])
  public onResize(
    event: Event
  ): void {
    if ((event.target as Window).innerWidth > 768) {
      this.menuHamburgerOpen = false;
      document.getElementsByTagName('body')[0].classList.remove('no-scroll');
    }
  }

  public backLinkOnClickEvent(): void {
    if (this.backLinkOptions?.onClick) {
      this.backLinkOptions?.onClick();
    }

    if (this.backLinkOptions?.backRouterLink) {
      const options: NavigationExtras = {
        ...(this.backLinkOptions?.isRouterLinkRelative ? { relativeTo: this.activatedRoute } : undefined),
        ...(this.backLinkOptions?.preserveQueryParams ? { queryParamsHandling: 'preserve' } : null),
      };

      this.router.navigate(
        this.backLinkOptions?.backRouterLink,
        options
      );
    }
  }

  private selectMenuItemBasedOnUrlOnRouterNavigationEnd(): void {
    /** Waiting for navigation to end for setting submenu item based on url.
     * Use case: when there is a canActivate guard, the selection can only be done when navigation succeeded >> NavigationEnd
     **/
    this.router
      .events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.selectMenuItemBasedOnUrl();
      });
  }

  private selectMenuItemBasedOnUrl(): void {
    const menuItemToSelect =
      this._submenuItems
        ?.filter((menuItem) => {
          let parentPath = '';

          // If relative paths are use, get parent path to compare
          if (menuItem.navigationExtras?.relativeTo) {
            parentPath = menuItem
              .navigationExtras
              ?.relativeTo
              ?.pathFromRoot
              .map((activatedRoute) => {
                return activatedRoute.snapshot.url;
              })
              .filter(urlSegment => !!urlSegment[0])
              .map(([urlSegment]) => urlSegment.path)
              .join('/') + '/';
          }

          return parentPath + menuItem.path?.replace(/(^\/)|(\?.*|#.*)/g, '') === this.router.url?.replace(/(^\/)|(\?.*|#.*)/g, '');
        }) || [];

    this.selectItem(menuItemToSelect[0]);
  }

  private filterMenuItemsOnDisplayEnvironments(): void {
    this._submenuItems = this._submenuItems?.filter((menuItem) => {
      return menuItem.rsIfEnvironment ? menuItem.rsIfEnvironment.includes(this.environment.environment) : true
    });
  }

  /** Will unselect all menu items and select the one provided */
  private selectItem(
    menuItem: SubheaderMenuItems
  ): void {
    if (menuItem && this._submenuItems?.length) {
      this._submenuItems
        .forEach((subMenuItem) => {
          subMenuItem.selected = false;
        });

      this._submenuItems.find(subMenuItem => subMenuItem === menuItem)!.selected = true;
    }
  }
}
