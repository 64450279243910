import {
  Inject,
  inject,
  InjectionToken,
  ModuleWithProviders,
  NgModule,
  Optional,
  provideAppInitializer,
  SkipSelf
} from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { RsHttpErrorsInterceptor } from './interceptors/rs-http-error-interceptor/rs-http-errors-interceptor.service';
import { setAppSkin } from '../../../scripts/applicationsSkinSetter';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { RsMultiHttpTranslateLoader } from './modules/rs-custom-ngx-translate/rs-multi-http-translate-loader';
import { RsMissingTranslationHandler } from './modules/rs-custom-ngx-translate/rs-missing-translation-handler';
import { TranslationService } from '../../services/translate/translate.service';
import { EnvironmentConfig } from './models/apps-environment';
import {
  RsAppInitializerReplaySubject
} from './components/web-component-app-initializer/classes/RsAppInitializerReplaySubject';
import {
  RS_WEB_COMP_APP_INITIALIZER_HTML_ELEMENT_NAME,
  RsWebComponentAppInitializerModule
} from './components/web-component-app-initializer/rs-web-component-app-initializer.module';
import { RsBlobErrorHttpInterceptor } from './interceptors/RsBlobErrorHttpInterceptor.interceptor';
import { RsAuthenticationService } from './modules/rs-authentication/services/rs-authentication.service';
import { UserInfoCookieAppName } from './modules/rs-authentication/enums/UserInfoCookieAppName.enum';
import { RsAuthenticationModule } from './modules/rs-authentication/rs-authentication.module';
import { appInitializerFactory } from './factory/app-initializer.factory';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { RsMomentDateAdapter } from '../../shared/adapters/rs-moment-date-adapter';
import { MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { ToastrModule } from 'ngx-toastr';

setAppSkin();

/**
 * @const ENVIRONMENT
 * Injection token for the environment interface to be provided by the applications.
 */
export const ENVIRONMENT = new InjectionToken<EnvironmentConfig>('ENVIRONMENT defined in RsCoreModule');
export const RS_CURRENT_APP_NAME = new InjectionToken<UserInfoCookieAppName>('RS_CURRENT_APP_NAME defined in RsCoreModule');
export const CONDITIONS_TO_INITIALIZE_APP = new InjectionToken<RsAppInitializerReplaySubject[]>('Conditions array to initialize app');
export const APP_INITIALIZER_SUCCESS_CALLBACK = new InjectionToken<() => void>('Callback if app successfully initialized');

const
  translationsLoaded$ = new RsAppInitializerReplaySubject(1),
  hasUserAccessToThisApp$ = new RsAppInitializerReplaySubject(1);

@NgModule({
  imports: [
    ToastrModule.forRoot({
      preventDuplicates: true,
    }),
    TranslateModule
      .forRoot({
        loader: {
          provide: TranslateLoader,
          useClass: RsMultiHttpTranslateLoader,
          deps: [
            HttpClient,
            ENVIRONMENT,
            RS_CURRENT_APP_NAME,
            'translationsLoaded'
          ]
        },
        missingTranslationHandler: {
          provide: MissingTranslationHandler,
          useClass: RsMissingTranslationHandler
        }
      }),
    RsWebComponentAppInitializerModule,
    RsAuthenticationModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RsHttpErrorsInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RsBlobErrorHttpInterceptor,
      multi: true
    },
    {
      provide: 'translationsLoaded',
      useValue: translationsLoaded$,
    },
    {
      provide: CONDITIONS_TO_INITIALIZE_APP,
      useValue: [
        hasUserAccessToThisApp$,
        translationsLoaded$
      ]
    },
    { provide: DateAdapter, useClass: RsMomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }
  ],
})
export class RsCoreModule {
  private static appInitializerSuccessCallBack?: (() => void);

  constructor(
    @Optional() @SkipSelf() private rsCoreModule: RsCoreModule,
    private readonly translationService: TranslationService,
    private readonly rsAuthenticationService: RsAuthenticationService,
    @Inject(RS_CURRENT_APP_NAME) private readonly currentAppName: keyof typeof UserInfoCookieAppName,
    @Inject(ENVIRONMENT) private readonly environment: EnvironmentConfig
  ) {
    if (rsCoreModule) {
      throw new Error('RsCoreModule is already loaded. Import it only once!');
    }
    // All users should have access to mgt because of profile pages (via topbar event if MGT is not in token apps array) and public areas
    // Allow Cypress tests headless mode (with localhost)
    if (this.currentAppName === 'MGT' || this.environment.topBarEnvironment === 'dev') {
      this.setHasUserAccessToThisApp$({
        hasUserAccessCondition: true,
        errorMessage: ''
      });
    } else {
      this.setHasUserAccessToThisApp$({
        hasUserAccessCondition: this.rsAuthenticationService.canUserAccessApp(),
        errorMessage: 'user has no access to application'
      });
    }
  }

  /** @param appInitializerSuccessCallBack function to execute in case of success
   * @param appName must be the same as the ones provided by the user-info cookie
   * @param translationResources IRsTranslateLoaderTranslationResource[]
   * @param environment IEnvironment
   **/
  public static forRoot({
    appInitializerSuccessCallBack,
    appName,
    environment
  }: {
    appInitializerSuccessCallBack?: (() => void),
    appName: UserInfoCookieAppName,
    environment: EnvironmentConfig
  }): ModuleWithProviders<RsCoreModule> {

    RsCoreModule.appInitializerSuccessCallBack = appInitializerSuccessCallBack;

    return {
      ngModule: RsCoreModule,
      providers: [
        provideAppInitializer(() => {
          const initializerFn = (appInitializerFactory)(
            inject(CONDITIONS_TO_INITIALIZE_APP),
            inject(RS_WEB_COMP_APP_INITIALIZER_HTML_ELEMENT_NAME),
            inject(APP_INITIALIZER_SUCCESS_CALLBACK)
          );
          return initializerFn();
        }),
        {
          provide: ENVIRONMENT,
          useValue: environment
        },
        {
          provide: APP_INITIALIZER_SUCCESS_CALLBACK,
          useValue: appInitializerSuccessCallBack
        },
        {
          provide: RS_CURRENT_APP_NAME,
          useValue: appName
        },
      ]
    };
  }

  private setHasUserAccessToThisApp$({
    hasUserAccessCondition,
    errorMessage
  }: {
    hasUserAccessCondition: boolean;
    errorMessage: string;
  }): void {
    if (hasUserAccessCondition) {
      hasUserAccessToThisApp$.next(true);
      this.loadTranslations();
    } else {
      hasUserAccessToThisApp$.error({
        type: 'UNAUTHORIZED',
        message: errorMessage
      });
    }

    hasUserAccessToThisApp$.complete();
  }

  private loadTranslations(): void {
    this.translationService.initialize();
  }
}
