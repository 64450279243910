import { Component, Input, OnInit } from '@angular/core';
import { AppInitializerErrorType } from './models/app-initializer-error-type';

@Component({
  selector: 'rs-wc-app-initializer-component[showReloadButton]',
  templateUrl: './app-initializer.component.html',
  styleUrls: ['./app-initializer.component.scss'],
  standalone: false
})
export class AppInitializerComponent implements OnInit, AppInitializerComponent {
  public translations = {
    loading: {
      en: 'Loading app',
      nl: 'Laden app',
      fr: 'Chargement de l\'application'
    },
    translationsError: {
      sorry: {
        en: 'We are sorry',
        nl: 'Het spijt ons',
        fr: 'Nous sommes désolés'
      },
      msg: {
        en: 'An error occurred while loading translations.<br/> Please reload page.',
        nl: 'Er is een fout opgetreden tijdens het laden van de vertalingen.',
        fr: 'Une erreur est arrivé durant le chargement des traductions.'
      },
      buttonCopy: {
        en: 'Click here to reload page',
        nl: 'Klik hier om de pagina opnieuw te laden',
        fr: 'Cliquez-ici pour rafraichir la page'
      },
      pageNotFound: {
        en: 'Page not found',
        nl: 'Pagina niet gevonden',
        fr: 'Page non trouvée'
      }
    }
  };
  public currentLanguage: 'nl' | 'en' | 'fr' = 'en';
  public _errorType?: AppInitializerErrorType;

  @Input()
  public set errorType(errorType: AppInitializerErrorType) {
    this._errorType = errorType;
  }

  public ngOnInit(): void {
    this.currentLanguage = this.setCurrentLang() as 'nl' | 'en' | 'fr';
  }

  public reloadPage(): void {
    window.location.reload();
  }

  private setCurrentLang(): string {
    const navigatorLang = navigator.language.split('-')[0];
    return ['en', 'fr', 'nl'].includes(navigatorLang) ? navigatorLang : 'en';
  }
}
