import { Component } from '@angular/core';
import { ActivatedRoute, ChildrenOutletContexts } from '@angular/router';
import { DarUiRoutesAnimation } from '@app/route-animations';

@Component({
  selector: 'dar-ui-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [DarUiRoutesAnimation],
  standalone: false
})
export class AppComponent {
  public readonly title = 'dar-ui';
  public currentStep = 0;

  constructor(
    private contexts: ChildrenOutletContexts,
    private activatedRoute: ActivatedRoute,
  ) {
  }

  public getRouteAnimationData(): unknown {
    return this.contexts.getContext('primary')?.route?.snapshot?.data?.['animation'];
  }

  public onActivatedRoute(): void {
    this.currentStep = this.activatedRoute.firstChild?.snapshot?.data?.['step'];
  }
}

