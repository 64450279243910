import { Component, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { CdkDrag, CdkDragDrop, CdkDragPlaceholder, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import { ColumnSpecification, ColumnWithLabelAndId } from '../../models/custom-types';
import { NgClass, NgForOf, NgIf } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TranslateModule } from '@ngx-translate/core';
import { RsDataCyDirective } from '../../directives/data-cy/data-cy.directive';

type TypeTableColumnsState = { label: string; display: boolean; columnId: string; }[];

/** The component will hereinafter referred to as tcr
 * For the expand button to work add the renta-tcr-expandable-area class to the wrapper you want to expand.
 */
@Component({
  selector: 'renta-table-columns-reorderer',
  templateUrl: './table-columns-reorderer.component.html',
  styleUrls: ['./table-columns-reorderer.component.scss'],
  imports: [
    NgClass,
    MatIconModule,
    NgIf,
    MatButtonModule,
    CdkDropList,
    NgForOf,
    CdkDrag,
    RsDataCyDirective,
    CdkDragPlaceholder,
    MatCheckboxModule,
    TranslateModule,
  ],
  host: { class: '' } // Will add the classes provided in <renta-table-columns-reorderer class=""></renta-table-columns-reorderer>
})
export class TableColumnsReordererComponent implements OnInit {

  /** Exhaustive Array of table columns
   * Be sure to provide the default array of columns, not the ones from the localstorage
   */
  @Input({ required: true }) public tableColumns!: ColumnSpecification[];

  /** Header's text */
  @Input({ required: true }) public headerLabel!: string;

  /** Unique identifier to store the table columns state on localStorage
   *
   * This will be used by user so provide the current user loginString
   */
  @Input({ required: true }) public localStorageKey!: string;

  /** Provided columns will be hiddend and therefor not allowed to be dragged
   * @input array of column label to be hidden
   */
  @Input() public disabledColumns?: string[];

  /** Provided columns will be set as unchecked by default and won't be displayed
   * but the user is still able to check them again.
   * @input array of column label to be unchecked by default
   */
  @Input() public columnsToDisableByDefault?: ColumnSpecification[];

  /** If provided the expand button will be shown
   * The provided element will be toggled with class .rs-tcr-expandable-area
   * This area will be expanded to cover the full screen
   */
  @Input() public expandableArea?: HTMLElement;

  /** Event emitted when the box is opened. */
  @Output() public opened: EventEmitter<void> = new EventEmitter();

  /** Event emitted when the box is closed. */
  @Output() public closed: EventEmitter<void> = new EventEmitter();

  /** Event emitted when the columns are reorderer. */
  @Output() public columnsStateModified: EventEmitter<string[]> = new EventEmitter();

  /** Event emitted when expand button is clicked. */
  @Output() public expandedTable: EventEmitter<boolean> = new EventEmitter();

  public isOpen = false;
  public expandTable = false;
  public tableColumnsState!: TypeTableColumnsState;

  public constructor(public renderer: Renderer2) {}

  /** use to close Tcr
   * Then use getter and setter to listen to changes
   * @input boolean
   */
  public _close: boolean = true;

  @Input()
  public set close(close: boolean) {
    this._close = close;
    if (close && this.isOpen) {
      this.toggleTcrState();
    }
  }

  /** Init user table columns state */
  public ngOnInit(): void {
    // use setTimeout to avoid state change after check error
    setTimeout((): void => {
      this.getUserTableColumnsState();
    }, 10);
  }

  /** Toggle state of tcr
   * opened/closed event emitted to the parent based on tcr toggle state
   */
  public toggleTcrState(): void {
    this.isOpen = !this.isOpen;
    const emitter = this.isOpen ? 'opened' : 'closed';
    this[emitter].emit();
  }

  /** If expandableArea element is provided toggle .rs-tcr-expandable-area class on the element
   * Emits event to parent
   */
  public expandTableToggler(): void {
    // Condition on template > if expandableArea not provided the button is not displayed
    this.expandTable = !this.expandTable;
    const classHandler = this.expandTable ? 'addClass' : 'removeClass';
    this.renderer[classHandler](this.expandableArea, 'rs-tcr-expandable-area');
    this.toggleTcrState();
  }

  /** Called when items are draged and dropped
   *
   * @param {CdkDragDrop<string[]>} event: CdkDragDrop<{ label: string; display: boolean }[]>
   * @returns {string[]} tableColumnsState: string[] `The modified columns array`
   */
  public cdkDropListDropped(event: CdkDragDrop<string[]>): void {
    moveItemInArray(
      this.tableColumnsState!,
      event.previousIndex,
      event.currentIndex
    );
    this.saveAndEmitTabelState();
  }

  /** Save teble columns order & display on localstorage
   * Emit new columns state
   */
  public saveAndEmitTabelState(): void {
    // Store cols state
    localStorage.setItem(
      this.localStorageKey,
      JSON.stringify(this.tableColumnsState)
    );

    // Emit new column state
    const columnNames = this.tableColumnsState
      ?.filter((element) => element.display)
      .map((element) => element.columnId);

    this.columnsStateModified.emit(columnNames);
  }

  public isColumnAllowed(columnName: string): boolean {
    return !this.disabledColumns
      ?.map((column) => column.toLowerCase())
      .includes(columnName.toLowerCase());
  }

  /** @ignore */
  private getUserTableColumnsState(): void {
    this.tableColumnsState = this.getColumnsState(this.tableColumns);
    const localStorageColumnsState: TypeTableColumnsState = JSON.parse(
      localStorage.getItem(this.localStorageKey)!
    );

    if (localStorageColumnsState) {
      let validColumnsState: TypeTableColumnsState =
        localStorageColumnsState.filter((columnStateFromLocalStorage) =>
          this.tableColumnsState.find(
            (tableColumnState) =>
              tableColumnState.columnId === columnStateFromLocalStorage.columnId
          ));

      if (validColumnsState.length) {
        const newTableColumns = this.tableColumnsState.filter(
          (value) =>
            !validColumnsState.find(
              (object) => object.columnId === value.columnId
            )
        );

        validColumnsState = validColumnsState.concat(newTableColumns);

        this.tableColumnsState = validColumnsState;
      }

      this.emitColumnsState();
    } else if (this.columnsToDisableByDefault?.length) {
      this.emitColumnsState();
    }
  }

  /** @ignore */
  private emitColumnsState(): void {
    const newColumnsState = this.tableColumnsState
      .filter((value) => value.display)
      .map((value) => value.columnId);
    this.columnsStateModified.emit(newColumnsState);
  }

  /** @ignore */
  private getColumnsState(
    tableColumns: ColumnSpecification[]
  ): TypeTableColumnsState {
    const tableColumnsState: TypeTableColumnsState = [];
    tableColumns.forEach((value: string | ColumnWithLabelAndId) => {
      if (typeof value !== 'string' && value?.label) {
        tableColumnsState.push({
          label: value.label,
          columnId: value.columnId,
          display: this.getDisplayFromLocalStorageOrColumnsToDisplay(
            value.label
          ),
        });
      } else if (typeof value === 'string') {
        tableColumnsState.push({
          label: value,
          columnId: value,
          display: this.getDisplayFromLocalStorageOrColumnsToDisplay(value),
        });
      }
    });
    return tableColumnsState;
  }

  /** @ignore */
  private getDisplayFromLocalStorageOrColumnsToDisplay(
    column: string
  ): boolean {
    const tableColumnsStateFromLocalStorage: TypeTableColumnsState = JSON.parse(
      localStorage.getItem(this.localStorageKey)!
    );
    const columnStateFromLocalStorage = tableColumnsStateFromLocalStorage?.find(
      (state) => state.columnId === column
    );
    const isColumnDisabledByDefault =
      !this.columnsToDisableByDefault?.includes(column);
    return (
      columnStateFromLocalStorage?.display ?? isColumnDisabledByDefault ?? true
    );
  }
}
