import { Component, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { NgClass } from '@angular/common';

@Component({
  selector: 'rs-active-inactive-icon',
  templateUrl: './rs-active-inactive-icon.component.html',
  styleUrls: ['./rs-active-inactive-icon.component.scss'],
  imports: [MatIconModule, NgClass]
})
export class RsActiveInactiveIconComponent  {
  public condition = input.required<boolean>();
}
