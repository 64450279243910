import { Component, OnInit } from '@angular/core';
import { Toast, ToastPackage, ToastrModule, ToastrService } from 'ngx-toastr';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { RsMessageHandlerData } from '../models/rs-message-handler-data';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { RsTranslatePipe } from '../../../pipes/translatable-string/translatable-string.pipe';

@Component({
  selector: 'rs-message-handler',
  templateUrl: './rs-message-handler.component.html',
  styleUrls: ['./rs-message-handler.component.scss'],
  imports: [
    CommonModule,
    TranslateModule,
    MatIconModule,
    MatButtonModule,
    ToastrModule,
    RsTranslatePipe
  ]
})
export class RsMessageHandlerComponent extends Toast implements OnInit {

  public data?: RsMessageHandlerData;

  public msg?: string;

  constructor(
        public readonly translateService: TranslateService,
        protected override toastrService: ToastrService,
        public override toastPackage: ToastPackage
  ) {
    super(toastrService, toastPackage);
  }

  public ngOnInit(): void {
    this.setMessage();
  }

  private setMessage(): void {
    this.data = this.toastPackage.config.payload;

    if (this.message) {
      this.msg = this.data?.isPlainText ? this.message : this.translateService.instant(this.message);
    } else {
      switch (this.data?.type) {
        case 'success':
          this.msg = this.translateService.instant('SUCCESS_MSG.GLOBAL');
          break;

        case 'error':
          this.msg = this.translateService.instant('ERROR_MSG.GLOBAL');
          break;
      }
    }
  }
}
