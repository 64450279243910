import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from '@app/app.module';
import { environment } from '@environments/environment';
import * as Sentry from '@sentry/angular';
import 'hammerjs';
import { RsSentry } from '@lib/utils/rs-senty-init';

new RsSentry({
  dsn: 'https://2e432a0f02234d67bf7f97810f13d5dd@o268277.ingest.sentry.io/6602682',
  enabled: environment.enableSentryMonitoring,
  environment: environment.environment,
  sentryReleaseName: environment.sentryReleaseName
}).sentryInit();

Sentry.getCurrentScope().setTag('app.name', 'DAR');
Sentry.getCurrentScope().setTag('app.environment', environment.environment as string);

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
