import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[darBlurOnEnter]',
  standalone: false
})
export class BlurOnEnterDirective {

  constructor(
    public el: ElementRef
  ) {
  }

  @HostListener('keydown', ['$event'])
  public onKeyDown(event: KeyboardEvent): void {

    if (event.key === 'Enter') {
      this.el.nativeElement.blur();
    }
  }

}
