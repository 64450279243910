import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

/** Declare a variable in the template.
 *
 * Usage
 *```html
 <i *rsVar="false as variable">{{ variable | json }}</i>
 ```
 */
@Directive({
  selector: '[rsVar]',
  standalone: false
})
export class RsNgVarDirective {
  public context: { $implicit?: unknown; rsVar?: unknown } = {};

  constructor(
    private vcRef: ViewContainerRef,
    private templateRef: TemplateRef<unknown>,
  ) {
  }

  @Input()
  public set rsVar(context: Object) {
    this.context.$implicit = this.context.rsVar = context;
    this.updateView();
  }

  private updateView(): void {
    this.vcRef.clear();
    this.vcRef.createEmbeddedView(this.templateRef, this.context);
  }
}
