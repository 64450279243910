import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';

/** ### rs-overview-filters:
 *
 * #### Will add a default header with apply and clear buttons
 *
 * @example
 * <rs-overview-filters
 *   [canApply]="true"
 *   (onApply)="myApplyFunction()"
 *   (onClearFilters)="myClearFunction()"
 *   (onClose)="myCloseFunction()"
 * >
 *  [ADD YOUR CUSTOM FORM / CONTENT HERE]
 * </rs-overview-filters>
 *
 * */
@Component({
  selector: 'rs-overview-filters',
  templateUrl: './rs-overview-filters.component.html',
  styleUrls: ['./rs-overview-filters.component.scss'],
  imports: [
    TranslateModule,
    MatIconModule,
    CommonModule,
    MatButtonModule
  ]
})
export class RsOverviewFiltersComponent {

  /** ### @Input displayClose
   * Boolean value to indicate if the cross to close the filters should be displayed
   * */
  @Input('displayClose') public displayClose = false;

  /** ### @Input canApplyFilters
   * Boolean value to indicate if the filters can be applied or not
   * */
  @Input('canApply') public canApplyFilters = true;

  /** ### @Output onApply
   * Will emit event when apply button is clicked
   * */
  @Output('onApply') public onApplyFilters = new EventEmitter();

  /** ### @Output onClearFilters
   * Will emit event when clear filters button is clicked
   * */
  @Output('onClearFilters') public onClearFilters = new EventEmitter();

  /** ### @Output onCloseFilters
   * Will emit an event when close filters button is clicked
   * */
  @Output('onClose') public onCloseFilters = new EventEmitter();

}
