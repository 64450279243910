import { Directive, Input } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';

@Directive({
  selector: '[rs-ngrx-form]',
  standalone: false
})
export class RsNgrxFormDirective {
  /** Usage:
   *
   * ```html
   <form
   [rs-ngrx-form-emitEvent]="boolean (Optional - Default = true)"
   [rs-ngrx-form]="form_state$ | async"
   >
   ...
   </form>
   ```
   */
  @Input('rs-ngrx-form-emitEvent') public emitEvent: boolean = true;

  /** Usage:
   *
   * ```html
   <form
   [rs-ngrx-form-onlySelf]="boolean (Optional - Default = true)"
   [rs-ngrx-form]="form_state$ | async"
   >
   ...
   </form>
   ```
   */
  @Input('rs-ngrx-form-onlySelf') public onlySelf: boolean = true;

  constructor(
    private formGroupDirective: FormGroupDirective
  ) {
  }

  /** Usage:
   *
   * ```html
   <form
   [rs-ngrx-form-emitEvent]="boolean (Optional - Default = true)"
   [rs-ngrx-form-onlySelf]="boolean (Optional - Default = true)"
   [rs-ngrx-form]="form_state$ | async"
   >
   ...
   </form>
   ```
   */
  @Input('rs-ngrx-form')
  public set data(val: { [key: string]: unknown }) {
    if (val) {
      this.formGroupDirective.form.patchValue(val, {
        onlySelf: this.onlySelf,
        emitEvent: this.emitEvent
      });
    }
  }
}
